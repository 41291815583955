<template lang="pug">
.dialog

  ClientOnly
    teleport(to="#modals")
      .dialog-popup(v-if="open")

        .dialog-popup-dismiss(@click="close")

        .dialog-popup-content(:style="{ background, width }")

          .dialog-popup-dismiss-button(@click="close") X

          .dialog-header
            slot(name="header", :close="close")

              .dialog-title {{ title }}

          .dialog-body
            slot(:close="close")

          .dialog-footer
            slot(name="footer", :close="close")

</template>
<script setup lang="ts">
import { useAppScroll } from '~/store/appScroll'

const appScroll = useAppScroll()

const props = defineProps({
  title: {
    type: String
  },
  open: {
    type: Boolean,
    required: true
  },
  width: {
    type: String,
    default: '480px'
  },
  background: {
    type: String,
  }
})

const emit = defineEmits(['update:open'])

const close = () => {
  emit('update:open', false)
}

watch(() => props.open, (open) => {
  if (open) {
    appScroll.disableScroll()
  } else {
    appScroll.enableScroll()
  }
})

onDocumentKeydown('Escape', close)

onUnmounted(() => {
  if (props.open) {
    appScroll.enableScroll()
  }
})
</script>
<style lang="sass" scoped>
.dialog
  display: inline-block
</style>
<style lang="sass">
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

#modals
  .dialog-popup
    position: fixed
    display: flex
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 9998
    align-items: flex-start
    justify-content: center
    padding: 16px
    padding-top: 20vh

    @include mobile
      align-items: center
      padding: 16px

    .dialog-popup-dismiss
      position: absolute
      top: -50vh
      left: -50vw
      width: 200vw
      height: 200vh
      background-color: rgba(0, 0, 0, 0.25)
      animation: fade-in ease-out .16s forwards

    .dialog-popup-content
      position: relative
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: stretch
      gap: 16px
      width: 100%
      max-width: 100%
      max-height: 640px
      background-color: $beige
      color: #000
      border-radius: 8px
      z-index: 9999
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2)
      opacity: 0
      transform: translateY(32px)
      animation: fade-in ease .3s .3s forwards, slide-up ease .3s .3s forwards

      @include breakpoint(phablet)
        width: 480px

      @keyframes slide-up
        from
          transform: translateY(32px)

        to
          transform: translateY(0)


      .dialog-popup-dismiss-button
        position: absolute
        top: -14px
        right: -14px
        width: 28px
        height: 28px
        border-radius: 50%
        background-color: $magenta
        box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)
        color: #FFF
        line-height: 28px
        font-weight: bold
        text-align: center
        cursor: pointer

        &:hover
          background-color: scale-color($magenta, $lightness: +15%)

    .dialog-header
      padding: 16px 16px 0 16px

      .dialog-title
        font-size: 18px
        font-weight: bold

    .dialog-body
      padding: 0 16px
      overflow-x: hidden
      overflow-y: auto

    .dialog-footer
      padding: 0 16px 16px 16px
</style>
